/**
 * This file contains all the keys for Optimizely experiments ran on the app
 * Includes a type-safe version of the useExperiment hook offered by Optimizely's SDK
 * Reference: https://docs.developers.optimizely.com/full-stack/docs/hooks-react
 */

import { OptimizelyContext, useDecision } from "@optimizely/react-sdk";
import { useCallback, useContext } from "react";

export enum IOptimizelyExperiments {
  HomeDashboard = "home_dashboard_1221",
  LegalEntityForm = "legal_entity_form_0124",
}

interface IOptimizelyUserAttributes {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [name: string]: any;
}

interface IOptimizelyHookOptions {
  autoUpdate?: boolean;
  timeout?: number;
}

interface IOptimizelyHookOverrides {
  overrideUserId?: string;
  overrideAttributes?: IOptimizelyUserAttributes;
}

// Alias to context-friendly booleans
type ClientReady = boolean;
type DidTimeout = boolean;

export enum OptimizelyDecideOption {
  DISABLE_DECISION_EVENT = "DISABLE_DECISION_EVENT",
  ENABLED_FLAGS_ONLY = "ENABLED_FLAGS_ONLY",
  EXCLUDE_VARIABLES = "EXCLUDE_VARIABLES",
  IGNORE_USER_PROFILE_SERVICE = "IGNORE_USER_PROFILE_SERVICE",
  INCLUDE_REASONS = "INCLUDE_REASONS",
}

interface IOptimizelyDecideHooksOptions extends IOptimizelyHookOptions {
  decideOptions?: OptimizelyDecideOption[];
}

interface IOptimizelyUserInfo {
  id: string | null;
  attributes?: IOptimizelyUserAttributes;
}

export interface IOptimizelyDecisionResult {
  variationKey: string | null;
  // The boolean value indicating if the flag is enabled or not
  enabled: boolean;
  // The collection of variables associated with the decision
  variables: { [variableKey: string]: unknown };
  // The rule key of the decision
  ruleKey: string | null;
  // The flag key for which the decision has been made for
  flagKey: string;
  // A copy of the user context for which the decision has been made for
  userContext: IOptimizelyUserInfo;
  // An array of error/info messages describing why the decision has been made.
  reasons: string[];
}

/**
 * Retrieves the decision result for a flag key.
 * @param featureKey 	The key of the feature flag
 * @param options Optimizely Options object
 * @param overrides Optimisely overrides (e.g. override userId/vendorId manually)
 * @returns [decision: decision result object for the flag key, clientReady: boolean, didTimeout: boolean]
 */
const useOptimizelyDecision = (
  featureKey: IOptimizelyExperiments,
  options?: IOptimizelyDecideHooksOptions | undefined,
  overrides?: IOptimizelyHookOverrides | undefined,
): readonly [IOptimizelyDecisionResult, ClientReady, DidTimeout] => {
  const decisionData = useDecision(featureKey, { autoUpdate: true, ...options }, overrides);
  return decisionData;
};

type TrackFn = (val: string) => void;
const useOptimizelyTrack = (): TrackFn => {
  const { optimizely } = useContext(OptimizelyContext);
  const fn = useCallback((val: string) => {
    optimizely?.track(val, optimizely.user.id ?? undefined);
    // Don't include optimizely object b/c we don't want track fn to trigger rerenders if recreated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return fn;
};

export { useOptimizelyDecision, useOptimizelyTrack };
