/* eslint-disable react/style-prop-object */
import React from "react";

export const WindowButtons: React.FC = () => (
  <svg width="31" height="9" viewBox="0 0 31 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="4.20347" cy="4.58531" r="4.14634" fill="#F13E3E" />
    <circle cx="15.2606" cy="4.58531" r="4.14634" fill="#EBC233" />
    <circle cx="26.3172" cy="4.58531" r="4.14634" fill="#0DCF2C" />
  </svg>
);
