import { IPageAuthorization } from "~src/shared/auth/AuthProvider";
import { IPipeRouter } from "~src/shared/requests/router";

import { makeAuthHOC } from "./makeAuthHOC";

/**
 * Only allow vendors to view this page.
 */
export const VendorOnlyAuth = makeAuthHOC({
  name: "VendorOnlyAuth",
  apolloRouter: IPipeRouter.VENDOR,
  authorization: IPageAuthorization.VENDOR_ONLY,
});
