import styled from "@emotion/styled";
import { PropsWithChildren } from "react";

import { WindowButtons } from "./WindowButtons";

type IProps = PropsWithChildren<unknown>;

export const PseudoWindow: React.FC<IProps> = ({ children }) => {
  return (
    <Wrapper>
      <WindowButtons />
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.components.Changelog.PseudoWindow.background};
  border: 1px solid ${(props) => props.theme.components.Changelog.PseudoWindow.border};
  border-radius: 6px;
  padding: 4px 10px;
  img,
  video {
    border: 1px solid ${(props) => props.theme.components.Changelog.PseudoWindow.border};
    border-radius: 4px;
    width: 100%;
  }
  svg {
    margin-top: 7px;
    margin-bottom: 2px;
  }
`;
