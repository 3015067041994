import Link from "next/link";
import React from "react";

import { IGetUserProfileData } from "~src/shared/requests/authedRequests";

import { PseudoWindow } from "./components/PseudoWindow";
import { VideoWithFallbacks } from "./components/VideoWithFallbacks";
import type { IChangelogItem } from "./types";

export const getApplicableVendorChangelogItems = (
  vendorChangelogItems: readonly IChangelogItem[],
  user: IGetUserProfileData | undefined,
): IChangelogItem[] => {
  if (!user) {
    // KLUDGE(johnrjj) - Spreading array converts readonly IChangelogItem[] array to IChangelogItem[]
    return [...vendorChangelogItems];
  }
  return vendorChangelogItems.filter((item) => {
    // If item has a filter criteria, determine if user should be shown changelog item
    if (item.shouldShowItemToUser) {
      return item.shouldShowItemToUser(user);
    }
    // Default to always showing
    return true;
  });
};

// upload assets to
// https://console.cloud.google.com/storage/browser/production-assets
export const vendorChangelogItems: readonly IChangelogItem[] = [
  {
    createdAt: new Date("2021-11-12T00:00:00+0000"),
    version: "1.3.2",
    // Only show this release to US currency denominated users
    shouldShowItemToUser: (user: IGetUserProfileData) =>
      user.vendor.supportedCurrencies.includes("usd"),
    body: (
      <>
        <h2>Trade Agreement Update</h2>
        <p>
          As of November 12th 2021, we've updated our trading agreement. The changes are meant to
          clarify the terms and mechanics of trading based on feedback from traders like you.
        </p>
        <p>
          <a
            href="https://storage.googleapis.com/production-assets/Pipe-Agreement-11_12_21.pdf"
            target="_blank"
          >
            Click here to check out the updated version.
          </a>
        </p>
      </>
    ),
  },
  {
    createdAt: new Date("2021-10-20T00:00:00+0000"),
    version: "1.3.1",
    body: (
      <>
        <h2>Trade View Improvements</h2>
        <p>
          We've split <i>Revenue Per Period</i> into two columns — <i>MRR</i> and{" "}
          <i>Collection Period</i> — to help you better understand the impact of your trades.
        </p>
        <p>
          MRR is the monthly recurring revenue you make from the contract. Collection Period is the
          interval at which Pipe will collect payments from you if the contract is traded.
        </p>
        <p>
          We've also added tooltips to all column headers to provide contextual information while
          using the trade view.
        </p>
        <p>
          Lastly, your contracts can now be filtered by collection period. This is helpful if you
          only want to select contracts for a single period so that all your payments happen in even
          amounts.
        </p>
        <div style={{ width: "100%" }}>
          <img
            src="https://storage.googleapis.com/production-assets/pipe-trade-view-improvements.gif"
            style={{ width: "100%", objectFit: "contain" }}
          />
        </div>
      </>
    ),
  },
  {
    createdAt: new Date("2021-08-31T16:10:24+0000"),
    version: "1.3.0",
    body: (
      <>
        <h2>Account Security Improvements</h2>
        <p>
          We’ve introduced two new features to improve your account security:{" "}
          <Link href="/settings/members" passHref>
            <a>View Only users</a>
          </Link>{" "}
          and{" "}
          <Link href="/settings" passHref>
            <a>Two-Factor Authentication</a>
          </Link>
          .
        </p>
        <p>
          Account Administrators can now invite and set View Only users. View Only users can view
          the financial data provided by Pipe, but they can’t trade, link accounts, or manage users.
          This ensures the right people on your team have the right level of access.
        </p>
        <p>
          When Two-Factor Authentication is enabled, your account will require an additional
          temporary code when logging in. This significantly decreases the risk of unauthorized
          access to your account.
        </p>
        <p>
          At Pipe, your privacy and data security are always #1. Please take a few minutes to
          improve the security of your Pipe account with these two new features.
        </p>
      </>
    ),
  },
  {
    createdAt: new Date("2021-02-12T02:19:28+0000"),
    version: "1.2.0",
    body: (
      <>
        <h2>New Finance Center</h2>
        <p>
          After a lot of feedback, we’re releasing the new and improved finance center! This allows
          you and your finance team to track your upcoming and historical payments/payouts and
          generate detailed accounting statements.
        </p>
        <p>
          We’ve also added CSV exports so that your team can flexibly download the data they need
          for accounting and auditing.
        </p>
        <PseudoWindow>
          <VideoWithFallbacks
            webm="https://storage.googleapis.com/production-assets/releases/vendor/v1.2.0/finance-center.webm"
            mp4="https://storage.googleapis.com/production-assets/releases/vendor/v1.2.0/finance-center.mp4"
            gif="https://storage.googleapis.com/production-assets/releases/vendor/v1.2.0/finance-center.gif"
          />
        </PseudoWindow>
      </>
    ),
  },
];
