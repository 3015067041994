import { AiFillMacCommand } from "react-icons/ai";
import { FaTerminal } from "react-icons/fa";

import { ICommandInfo } from "~src/shared/command/types";
import { ICommandKMode } from "~src/shared/commandK/types";

import { IDestination } from "./goto";
import { NavFinance } from "./icons/NavFinance";
import { NavPastTrades } from "./icons/NavPastTrades";
import { NavTrade } from "./icons/NavTrade";

export const COMMAND_K_COMMANDS: readonly ICommandInfo[] = [
  {
    title: "Exit",
    command: { command: "command-k/exit" },
    bindings: ["esc"],
    hideInCommandKModal: true,
  },
  {
    title: "Back",
    command: { command: "command-k/back" },
    bindings: ["command+left", "ctrl+left"],
    hideInCommandKModal: true,
  },
  {
    title: "Command K Select",
    command: { command: "command-k/select" },
    bindings: ["enter"],
    hideInCommandKModal: true,
  },
  {
    Icon: AiFillMacCommand,
    title: "Command K",
    command: { command: "command-k/toggle" },
    bindings: ["command+k", "ctrl+k"],
  },
  {
    title: "Down",
    command: { command: "command-k/down" },
    bindings: ["down"],
    hideInCommandKModal: true,
  },
  {
    title: "Up",
    command: { command: "command-k/up" },
    bindings: ["up"],
    hideInCommandKModal: true,
  },
  {
    Icon: FaTerminal,
    title: "Shortcuts",
    command: {
      command: "command-k/show",
      args: { mode: ICommandKMode.COMMANDS, args: null },
    },
    bindings: ["?"],
  },
];

export const GOTO_COMMANDS: readonly ICommandInfo[] = [
  {
    Icon: NavTrade,
    title: "Go to Trading",
    command: {
      command: "goto",
      args: IDestination.TRADING,
    },
    bindings: ["g t"],
  },
  {
    Icon: NavPastTrades,
    title: "Go to Recent Trades",
    command: {
      command: "goto",
      args: IDestination.RECENT_TRADES,
    },
    bindings: ["g r"],
  },
  {
    Icon: NavFinance,
    title: "Go to Finance Center",
    command: {
      command: "goto",
      args: IDestination.FINANCE_CENTER,
    },
    bindings: ["g f"],
  },
];
