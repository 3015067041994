import React, { useEffect, useState } from "react";

import { Head } from "~src/designSystem/sortLater/Head";
import { useUser } from "~src/shared/auth/useUser";
import { useCommandMapping } from "~src/shared/command/hooks/useCommandMapping";
import { ICommandMappingName } from "~src/shared/command/types";
import { useEnv } from "~src/shared/env/useEnv";
import { IFeatureFlagName, useFeatureFlag } from "~src/shared/featureFlags";
import { HelpWidgetWrapper } from "~src/shared/helpWidget/helpWidgetWrapper";
import { V2Layout } from "~src/shared/layouts/V2Layout";
import { ITab } from "~src/shared/layouts/V2Layout/Header";
import { ISubnav } from "~src/shared/layouts/V2Layout/Subnav";
import { V2Main } from "~src/shared/layouts/V2Layout/V2Main";
import { IOptimizelyExperiments, useOptimizelyDecision } from "~src/shared/thirdParties/optimizely";
import { ReleaseModalTrigger } from "~src/vendor/changelog/modals/ReleaseModal/ReleaseModalTrigger";
import { ReleaseModalProvider } from "~src/vendor/changelog/modals/ReleaseModal/useReleaseModal";

import { COMMAND_K_COMMANDS } from "./commands";
import { useGoto } from "./goto";

interface IProps {
  children: React.ReactNode;
  noPadding?: boolean;
  noOverflowY?: boolean;
  maxContentWidth?: string;
  subnav?: ISubnav;
}

const TABS: ITab[] = [
  {
    title: "Trade",
    href: "/inbox",
    highlightPathIncludes: ["/inbox"],
  },
  {
    title: "Past Trades",
    href: "/past-trades",
    highlightPathIncludes: ["/past-trades"],
  },
  {
    title: "Finance",
    href: "/finance",
    highlightPathIncludes: ["/finance"],
  },
  {
    title: "Settings",
    href: "/settings",
    highlightPathIncludes: ["/settings"],
  },
];

/**
 * Layout of a vendor page.
 */
export const VendorLayout: React.FC<IProps> = (props) => {
  const { noPadding = false, noOverflowY = false, subnav, maxContentWidth, children } = props;
  const user = useUser();

  const env = useEnv();
  const [homeDashboardFeatureDecision] = useOptimizelyDecision(
    IOptimizelyExperiments.HomeDashboard,
  );
  const homeDashboardFeatureEnabled = homeDashboardFeatureDecision.enabled;

  const treasuryFeatureEnabled = useFeatureFlag(IFeatureFlagName.TREASURY);

  const [navItemTabs, setNavItemTabs] = useState<ITab[]>(TABS);

  const isLocal = env.DOMAIN === "local.pipe-dev.com";

  useEffect(() => {
    const extraTabs: ITab[] = [];
    if (homeDashboardFeatureEnabled || isLocal) {
      // If Home Dash / Revamp experiment, make Home Dash the first item
      // in the nav.
      extraTabs.push({
        title: "Analytics (Beta)",
        href: "/analytics",
        highlightPathIncludes: ["/analytics"],
      });
    }
    if (treasuryFeatureEnabled || isLocal) {
      extraTabs.push({
        title: "Treasury",
        href: "/treasury",
        highlightPathIncludes: ["/treasury", "/treasury/add"],
      });
    }

    // Add extra tabs if we have any
    if (extraTabs.length > 0) {
      setNavItemTabs([...TABS, ...extraTabs]);
    }
  }, [homeDashboardFeatureEnabled, env.DOMAIN, isLocal, treasuryFeatureEnabled]);

  useGoto();
  useCommandMapping(ICommandMappingName.CommandK, COMMAND_K_COMMANDS);
  return (
    <V2Layout>
      <Head />
      <HelpWidgetWrapper>
        <ReleaseModalProvider>
          {user.vendor.isActivated && <ReleaseModalTrigger />}
          <V2Main
            brandLinkHref="/inbox"
            navItems={navItemTabs}
            subnav={subnav}
            noPadding={noPadding}
            noOverflowY={noOverflowY}
            maxContentWidth={maxContentWidth}
          >
            {children}
          </V2Main>
        </ReleaseModalProvider>
      </HelpWidgetWrapper>
    </V2Layout>
  );
};
