/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { ModalInner } from "~src/designSystem/deprecated/Modal/ModalInner";
import { Content } from "~src/vendor/changelog/components/ChangelogItem";
import { IChangelogItem } from "~src/vendor/changelog/types";

interface IProps {
  item: IChangelogItem;
}

export const ReleaseModal: React.FC<IProps> = ({ item }) => {
  return (
    <ModalInner
      background="background"
      css={css`
        padding: 20px 34px;
        width: fit-content;
      `}
    >
      <Title>New Update!</Title>
      <Body>
        <Scrollable>{item.body}</Scrollable>
      </Body>
    </ModalInner>
  );
};

const Title = styled.h2`
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  margin-bottom: 27px;
  color: ${(props) => props.theme.components.ReleaseModal.title};
`;

const Body = styled(Content)`
  width: 525px;
  background-color: ${(props) => props.theme.components.ReleaseModal.background};
  border: 1px solid ${(props) => props.theme.components.ReleaseModal.border};
  box-sizing: border-box;
  border-radius: 4px;
  height: 380px;
`;

const Scrollable = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 26px;
  /* For some reason we apply margin on every div inside the body -_-. */
  margin: 0 !important;
`;
