import dayjs from "dayjs";
import { useEffect } from "react";
import semver from "semver";

import { useUser } from "~src/shared/auth/useUser";
import {
  getApplicableVendorChangelogItems,
  vendorChangelogItems,
} from "~src/vendor/changelog/items";
import type { IChangelogItem } from "~src/vendor/changelog/types";

import { useReleaseModalUserQuery } from "./__generated__/ReleaseModalTrigger";
import { useReleaseModal } from "./useReleaseModal";

const getLatestUnseenChangelogItem = (
  latestVersionSeen: string,
  changelogItems: IChangelogItem[],
): IChangelogItem | null => {
  return changelogItems.find((item) => semver.gt(item.version, latestVersionSeen)) ?? null;
};

/**
 * Including this component triggers the release modal whenever the user's latest version is out of date.
 */
export const ReleaseModalTrigger: React.FC = () => {
  const { openModal } = useReleaseModal();
  const user = useUser();
  const { data } = useReleaseModalUserQuery({ variables: { userID: user.publicID } });
  const additionalUserDataForChangelog = data?.users[0];

  useEffect(() => {
    if (additionalUserDataForChangelog === undefined) {
      return;
    }

    const latestVersionSeen = additionalUserDataForChangelog.latest_version_seen ?? "0.0.0";
    const applicableVendorChangelogItems = getApplicableVendorChangelogItems(
      vendorChangelogItems,
      user,
    );
    const latestUnseenChangelogItem = getLatestUnseenChangelogItem(
      latestVersionSeen,
      applicableVendorChangelogItems,
    );
    if (latestUnseenChangelogItem === null) {
      return;
    }

    // If the user was created after the unseen changelog entry, don't show a
    // popup.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const userCreatedAt = dayjs(additionalUserDataForChangelog.created_at!).toDate();
    if (latestUnseenChangelogItem.createdAt < userCreatedAt) {
      return;
    }

    openModal(latestUnseenChangelogItem);
  }, [openModal, additionalUserDataForChangelog, user]);

  return <></>;
};

graphql`
  query ReleaseModalUserQuery($userID: String!) {
    users(where: { public_id: { _eq: $userID } }) {
      public_id
      created_at
      latest_version_seen
    }
  }
`;
