import * as Types from '../../../../../__generated__/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateLatestVersionSeenVariables = Types.Exact<{
  userID: Types.Scalars['String'];
  version: Types.Scalars['String'];
}>;


export type UpdateLatestVersionSeen = { readonly __typename?: 'mutation_root', readonly update_users: { readonly __typename?: 'users_mutation_response', readonly affected_rows: number } | null };


export const UpdateLatestVersionSeenDocument = gql`
    mutation UpdateLatestVersionSeen($userID: String!, $version: String!) {
  update_users(
    _set: {latest_version_seen: $version}
    where: {public_id: {_eq: $userID}}
  ) {
    affected_rows
  }
}
    `;
export type UpdateLatestVersionSeenMutationFn = Apollo.MutationFunction<UpdateLatestVersionSeen, UpdateLatestVersionSeenVariables>;

/**
 * __useUpdateLatestVersionSeen__
 *
 * To run a mutation, you first call `useUpdateLatestVersionSeen` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLatestVersionSeen` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLatestVersionSeen, { data, loading, error }] = useUpdateLatestVersionSeen({
 *   variables: {
 *      userID: // value for 'userID'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useUpdateLatestVersionSeen(baseOptions?: Apollo.MutationHookOptions<UpdateLatestVersionSeen, UpdateLatestVersionSeenVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLatestVersionSeen, UpdateLatestVersionSeenVariables>(UpdateLatestVersionSeenDocument, options);
      }
export type UpdateLatestVersionSeenHookResult = ReturnType<typeof useUpdateLatestVersionSeen>;
export type UpdateLatestVersionSeenMutationResult = Apollo.MutationResult<UpdateLatestVersionSeen>;
export type UpdateLatestVersionSeenMutationOptions = Apollo.BaseMutationOptions<UpdateLatestVersionSeen, UpdateLatestVersionSeenVariables>;