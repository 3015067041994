import * as Types from '../../../../../__generated__/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReleaseModalUserQueryVariables = Types.Exact<{
  userID: Types.Scalars['String'];
}>;


export type ReleaseModalUserQuery = { readonly __typename?: 'query_root', readonly users: ReadonlyArray<{ readonly __typename?: 'users', readonly public_id: string, readonly created_at: string, readonly latest_version_seen: string | null }> };


export const ReleaseModalUserQueryDocument = gql`
    query ReleaseModalUserQuery($userID: String!) {
  users(where: {public_id: {_eq: $userID}}) {
    public_id
    created_at
    latest_version_seen
  }
}
    `;

/**
 * __useReleaseModalUserQuery__
 *
 * To run a query within a React component, call `useReleaseModalUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleaseModalUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleaseModalUserQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useReleaseModalUserQuery(baseOptions: Apollo.QueryHookOptions<ReleaseModalUserQuery, ReleaseModalUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReleaseModalUserQuery, ReleaseModalUserQueryVariables>(ReleaseModalUserQueryDocument, options);
      }
export function useReleaseModalUserQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReleaseModalUserQuery, ReleaseModalUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReleaseModalUserQuery, ReleaseModalUserQueryVariables>(ReleaseModalUserQueryDocument, options);
        }
export type ReleaseModalUserQueryHookResult = ReturnType<typeof useReleaseModalUserQuery>;
export type ReleaseModalUserQueryLazyQueryHookResult = ReturnType<typeof useReleaseModalUserQueryLazyQuery>;
export type ReleaseModalUserQueryQueryResult = Apollo.QueryResult<ReleaseModalUserQuery, ReleaseModalUserQueryVariables>;