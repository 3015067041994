interface IProps {
  className?: string;
  webm: string;
  mp4: string;
  gif: string;
  noLoop?: boolean;
}

export const VideoWithFallbacks: React.FC<IProps> = ({ className, webm, mp4, gif, noLoop }) => {
  return (
    <video className={className} width="100%" autoPlay loop={noLoop !== true} muted playsInline>
      <source src={webm} type="video/webm" />
      <source src={mp4} type="video/mp4" />
      <img src={gif} />
    </video>
  );
};
