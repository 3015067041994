import styled from "@emotion/styled";
import React from "react";

import { Format } from "~src/shared/formatters";
import { IChangelogItem } from "~src/vendor/changelog/types";

interface IProps {
  item: IChangelogItem;
}

export const ChangelogItem: React.FC<IProps> = ({ item }) => (
  <Wrapper>
    <div>
      <Format.Date date={item.createdAt} />
    </div>
    <Content>{item.body}</Content>
  </Wrapper>
);

export const Content = styled.div`
  width: 100%;
  min-width: 0;

  h2 {
    font-weight: bold;
    font-size: 19px;
    line-height: 23px;
    margin-bottom: 20px;
    color: ${(props) => props.theme.components.Changelog.Title.text};
  }
  p {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 20px;
    color: ${(props) => props.theme.components.Changelog.Body.text};
  }
  & > div {
    margin-top: 28px;
    margin-bottom: 28px;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-column-gap: 30px;
  max-width: 100%;
`;
