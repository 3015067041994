import { useRouter } from "next/router";

import { useCommand } from "~src/shared/command/hooks/useCommand";
import { useCommandMapping } from "~src/shared/command/hooks/useCommandMapping";
import { ICommandMappingName } from "~src/shared/command/types";

import { GOTO_COMMANDS } from "./commands";

export enum IDestination {
  FINANCE_CENTER = "FINANCE_CENTER",
  RECENT_TRADES = "RECENT_TRADES",
  TRADING = "TRADING",
}

const destinations: { [d in IDestination]: string } = {
  [IDestination.RECENT_TRADES]: "/trades",
  [IDestination.TRADING]: "/inbox",
  [IDestination.FINANCE_CENTER]: "/finance",
};

export const useGoto = (): void => {
  const router = useRouter();

  useCommandMapping(ICommandMappingName.Goto, GOTO_COMMANDS);
  useCommand(
    "goto",
    (_, destination: IDestination) => {
      router.push(destinations[destination]);
    },
    [router],
  );
};
