/* eslint-disable react/style-prop-object */
import React from "react";

export const NavPastTrades: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 3.425C4.325 1.3375 7 0 10 0C15.525 0 20 4.475 20 10C20 15.075 16.225 19.25 11.325 19.9C11.3125 19.9 11.3 19.9031 11.2875 19.9063C11.275 19.9094 11.2625 19.9125 11.25 19.9125C10.8375 19.9625 10.425 20 10 20C4.475 20 0 15.525 0 10C0 9.3125 0.5625 8.75 1.25 8.75C1.9375 8.75 2.5 9.3125 2.5 10C2.5 14.1375 5.8625 17.5 10 17.5C10.8875 17.5 11.7125 17.3125 12.5 17.025V17.0375C15.4125 16.0125 17.5 13.2625 17.5 10C17.5 5.8625 14.1375 2.5 10 2.5C7.7875 2.5 5.8 3.475 4.425 5H6.25C6.9375 5 7.5 5.5625 7.5 6.25C7.5 6.9375 6.9375 7.5 6.25 7.5H1.25C0.5625 7.5 0 6.9375 0 6.25V1.25C0 0.5625 0.5625 0 1.25 0C1.9375 0 2.5 0.5625 2.5 1.25V3.425ZM8.75 5C8.75 4.3125 9.3125 3.75 10 3.75C10.6875 3.75 11.25 4.3125 11.25 5V9.4875L13.3875 11.6125C13.6125 11.8375 13.75 12.15 13.75 12.5C13.75 13.1875 13.1875 13.75 12.5 13.75C12.15 13.75 11.8375 13.6125 11.6125 13.3875L9.1125 10.8875C8.8875 10.6625 8.75 10.35 8.75 10V5Z"
    />
  </svg>
);
