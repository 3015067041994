import React, { PropsWithChildren, useCallback, useRef } from "react";

import { useUser } from "~src/shared/auth/useUser";
import { useModal } from "~src/shared/modals/modal-context";
import { ISegmentTrackEvent, useAnalytics } from "~src/shared/thirdParties/segment";
import { IChangelogItem } from "~src/vendor/changelog/types";

import { ReleaseModal } from ".";
import { useUpdateLatestVersionSeen } from "./__generated__/useReleaseModal";

interface IRelease {
  /**
   * Opens the release modal if no other modal is open.
   */
  openModal: (item: IChangelogItem) => void;
  closeModal: () => void;
}

const ReleaseModalContext = React.createContext<IRelease | null>(null);

type IProps = PropsWithChildren<unknown>;

export const ReleaseModalProvider: React.FC<IProps> = (props) => {
  const { children } = props;
  const { addAndOpenModal, clearStackAndCloseModal, modalState } = useModal();
  const { publicID: userID } = useUser();
  const [updateLatestVersionSeen] = useUpdateLatestVersionSeen();
  const { trackEvent } = useAnalytics();

  // Hold onto the latest changelog item while modal is open so we can reference throughout modal lifecycle
  const latestReleaseItemShownInModal = useRef<IChangelogItem | null>(null);

  const closeModal = useCallback(async () => {
    const latestChangelogItem = latestReleaseItemShownInModal.current;
    if (latestChangelogItem) {
      await updateLatestVersionSeen({
        variables: { version: latestChangelogItem.version, userID },
      });
    }
    latestReleaseItemShownInModal.current = null;
    trackEvent(ISegmentTrackEvent.VendorChangelogPopupClosed, {
      latestChangelogItem: latestReleaseItemShownInModal.current,
    });
    clearStackAndCloseModal();
  }, [clearStackAndCloseModal, trackEvent, updateLatestVersionSeen, userID]);

  const openModal = useCallback(
    (latestChangelogItem: IChangelogItem) => {
      if (modalState.stack.length > 0) {
        return;
      }
      latestReleaseItemShownInModal.current = latestChangelogItem;
      trackEvent(ISegmentTrackEvent.VendorChangelogPopupOpened, { latestChangelogItem });
      addAndOpenModal({
        config: {
          isCloseButtonHidden: false,
          isCloseOnOverlayClickDisabled: false,
          isStripesVisible: true,
          stripesColor: "stripesBackground",
          closeButtonPosition: "left",
          width: "fit-content",
          onClose: closeModal,
        },
        component: (
          <ReleaseModalContext.Provider value={{ openModal, closeModal }}>
            <ReleaseModal item={latestChangelogItem} />
          </ReleaseModalContext.Provider>
        ),
      });
    },
    [modalState.stack.length, trackEvent, addAndOpenModal, closeModal],
  );

  // Pass down a ref so we don't break function equality when used in dependency arrays
  const openModalRef = useRef(openModal);
  const closeModalRef = useRef(closeModal);

  return (
    <ReleaseModalContext.Provider
      value={{ openModal: openModalRef.current, closeModal: closeModalRef.current }}
    >
      {children}
    </ReleaseModalContext.Provider>
  );
};

/**
 * Allows opening/closing the onboarding modal.
 */
export const useReleaseModal = (): IRelease => {
  const ctx = React.useContext(ReleaseModalContext);
  if (ctx == null) {
    throw new Error("Not in release modal context");
  }
  return ctx;
};

graphql`
  mutation UpdateLatestVersionSeen($userID: String!, $version: String!) {
    update_users(_set: { latest_version_seen: $version }, where: { public_id: { _eq: $userID } }) {
      affected_rows
    }
  }
`;
