/* eslint-disable react/style-prop-object */
import React from "react";

export const NavFinance: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 6.9L9.5 11.9C9.7 12 9.8 12 10 12C10.2 12 10.3 12 10.5 11.9L19.5 6.9C19.8 6.7 20 6.4 20 6C20 5.6 19.8 5.3 19.5 5.1L10.5 0.1C10.3 0 10.2 0 10 0C9.8 0 9.7 0 9.5 0.1L0.5 5.1C0.2 5.3 0 5.6 0 6C0 6.4 0.2 6.7 0.5 6.9Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 9C18.8 9 18.7 9 18.5 9.1L10 13.9L1.5 9.1C1.3 9 1.2 9 1 9C0.4 9 0 9.4 0 10C0 10.4 0.2 10.7 0.5 10.9L9.5 15.9C9.7 16 9.8 16 10 16C10.2 16 10.3 16 10.5 15.9L19.5 10.9C19.8 10.7 20 10.4 20 10C20 9.4 19.6 9 19 9Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 13C18.8 13 18.7 13 18.5 13.1L10 17.9L1.5 13.2C1.3 13 1.2 13 1 13C0.4 13 0 13.4 0 14C0 14.4 0.2 14.7 0.5 14.9L9.5 19.9C9.7 20 9.8 20 10 20C10.2 20 10.3 20 10.5 19.9L19.5 14.9C19.8 14.7 20 14.4 20 14C20 13.4 19.6 13 19 13Z"
    />
  </svg>
);
