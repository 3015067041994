/* eslint-disable react/style-prop-object */
import React from "react";

export const NavTrade: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="8.57135" width="5.71429" height="11.4286" rx="1.34286" />
    <rect x="7.14282" y="4.28564" width="5.71429" height="15.7143" rx="1.34286" />
    <rect x="14.2856" y="-6.10352e-05" width="5.71429" height="20" rx="1.34286" />
  </svg>
);
