import { useRouter } from "next/router";

import { useMaybeUser } from "../auth/useUser";
import { IFeatureFlagName, useFeatureFlag } from "../featureFlags";
import { HelpWidgetPresentation } from "./index.presentation";

interface IProps {
  className?: string;
}

export const HelpWidget: React.FC<IProps> = ({ className }) => {
  const { asPath } = useRouter();
  const user = useMaybeUser();
  const canUseHelpWidget = useFeatureFlag(IFeatureFlagName.HELP_WIDGET);

  if (user == null || !canUseHelpWidget) {
    return null;
  }

  const { country } = user.vendor;

  return (
    <HelpWidgetPresentation className={className} country={country ?? undefined} path={asPath} />
  );
};
